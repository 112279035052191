import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  //faYoutubeSquare,
  faWhatsapp,
  faInstagram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import {
  faMapMarkerAlt,
  // faPhoneAlt,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

import '@styles/IconStyles.css';

const Footer = () => (
  <StyledSection id="contato">
    <FooterWrapper>
      <StyledContainer>
        <Copyright>
          <h2>Dra.<br />Sueny<br />Munarin</h2>
          <h4>CRM-PR 35.528<br />RQE 26.982</h4>
        </Copyright>
        <ContactInfo>
          <h3>Contato</h3>
          <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Endereço:</strong> Avenida Curitiba, 416 - Centro Médico Santa Rita - Zona 04 - Maringá (PR)</p>
          <p><FontAwesomeIcon icon={faWhatsapp} /> <strong>WhatsApp: </strong><a href="tel:44998804915">(44) 99880-4915</a></p>
          <p><FontAwesomeIcon icon={faEnvelope} /> <strong>E-mail:</strong> <EmailLink target="_blank" href="mailto:falecomigo@reumatologistamaringa.com.br">falecomigo@reumatologistamaringa.com.br</EmailLink></p>
          <SocialNetworks>
            <h3>Redes Sociais</h3>
            <SocialIcons>
              <ExternalLink
                href='https://www.facebook.com/dra.sueny.reumato/'
              >
                <FontAwesomeIcon className="facebookIcon" icon={faFacebookSquare} />
              </ExternalLink>
              <ExternalLink
                href='https://www.instagram.com/dra.sueny.reumato/'
              >
                <FontAwesomeIcon className="instagramIcon" icon={faInstagram} />
              </ExternalLink>
              <ExternalLink
                href='https://www.linkedin.com/in/sueny-munarin-sanches-91a254186/'
              >
                <FontAwesomeIcon className="linkedInIcon" icon={faLinkedin} />
              </ExternalLink>
              {/* {<FontAwesomeIcon className="youtubeIcon" icon={faYoutubeSquare} />} */}
            </SocialIcons>
          </SocialNetworks>
        </ContactInfo>
      </StyledContainer>
    </FooterWrapper>
  </StyledSection>
);

const ContactInfo = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  display: flex;
  flex-direction: column;
  max-width: 480px;

  h3 {
    text-align: center;
  }

  p {
    font-size: 20px;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: #000080;
  }

  a:visited {
    color: purple;
  }

  a:hover {
    color: white;
  }
`;

const SocialNetworks = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; 
`;

const SocialIcons = styled.div`
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const EmailLink = styled.a`
  text-decoration: none;
  font-size: 20px;

`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 2px;
    padding-right: 2px;
  }
`;

const StyledSection = styled(Section)`
  padding-top: 128px;
`;

export default Footer;
