import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';

const FAQS = [
  {
    title: 'Reumatismo só acontece em idoso?',
    content: () => (
      <>
        Não!<br/><br/>

        Reumatismo não é uma única doença.
        Existem mais de 200 tipos de doenças reumatológicas.<br/>
        A Reumatologia abrange doenças do:<br/>
        <ul>
          <li>
            sistema musculoesquelético (referente aos ossos, músculos, tendões, ligamentos e articulações);
          </li>
          <li>
          tecido conjuntivo (tecido que está por todo corpo, até nos vasos sanguíneos).
          </li>
        </ul>
        Essas doenças podem atingir pessoas de qualquer idade, desde crianças a idosos, tanto quanto mulheres ou homens.
      </>
    ),
  },
  {
    title: 'Como evitar a osteoporose?',
    content: () => (
      <>
        <p>Algumas dicas valiosas para evitar a osteoporose são:</p>
        <ol>
          <li>Atividade física</li>
          <li>Tomar sol</li>
          <li>Consumo de alimentos com cálcio</li>
          <li>Cessar tabagismo</li>
          <li>Diminuir consumo de álcool</li>
        </ol>
      </>
    ),
  },
  {
    title: 'Dor nas costas: quando procurar o médico?',
    content: () => (
      <>
        <ul>
          <li>Dor acompanhada por febre - temperatura maior que 37,8 graus</li>
          <li>Dor após algum trauma - Quedas, acidentes de carro/moto, ...</li>
          <li>Dor à noite ou pela manhã ou em repouso</li>
          <li>Dor associada a perda de peso</li>
          <li>Dor associada a alterações de força ou sensibilidade nas pernas</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Dor no dedão: o que pode ser?',
    content: () => (
      <>
        Uma causa relativamente comum de dor no polegar é a <strong>tenossinovite de Quervain</strong>.<br/>
        Causado por inflamação dos tendões de músculos: extensor curto do polegar e abdutor longo do polegar.<br/>
        A doença é mais incidente em <strong>mulheres</strong> entre <strong>30-50 anos</strong>.<br/>
        O motivo dessa inflamação pode ter relação com sobrecarga das atividades diárias, referente às mãos e punhos (uso de smartphones).
      </>
    ),
  },
  {
    title: 'Por que as juntas estalam?',
    content: () => (
      <>
        As articulações são banhadas por um líquido, o líquido sinovial, que funciona como um lubrificante.<br/>

        Esse líquido possui microbolhas formada por gases, como o nitrogênio.<br/>

        Quando estalamos as articulações, essas bolhas aumentam de tamanho e explodem, causando então o barulho característico do estalo.<br/>

        Quem estala os dedos, já deve ter notado que não é possível fazer esse barulhinho característico novamente logo após um estalo, pois é necessário que as microbolhas se formem novamente.
      </>
    ),
  },
  {
    title: 'Estalar as dedos “engrossa” as juntas?',
    content: () => (
      <>
        De acordo com estudos, não!
        <br/>
        Vários estudos foram feitos sobre o assunto, o mais conhecido é o realizado pelo médico Donald Unger.
        <br/>
        O Dr. Unger estalou os dedos da mão esquerda duas vezes ao dia por 50 anos, deixando a mão direita como controle.
        <br/>
        E após esse longo período, constatou que não houve nenhuma mudança articular ou de força decorrente desta prática.
      </>
    ),
  },
  {
    title: 'Doença dos Reis: o que é isso?',
    content: () => (
      <>
        Popularmente conhecida como a doença dos Reis, a Gota é uma condição muito importante nos consultórios de Reumatologia e também em Prontos Socorros!
        <br/>
        É a principal causa de artrite (“inflamação nas juntas”) em homens.
        <br/>
        Causa inflamação intensa da articulação, devido à deposição de cristais de ácido úrico.
        <br/>
        Tem relação importante com obesidade e dietas hipercalóricas, com excesso de carnes e consumo de bebidas alcóolicas, vindo daí sua relação com os reis.
      </>
    ),
  },
  {
    title: 'Dor de cotovelo: você tem?',
    content: () => (
      <>
        Uma das causas mais comum é a epicondilite lateral! 
        <br/>
        Esta pode ocorrer em 1 a 3% das pessoas, relacionada à sobrecarga, como movimento repetitivos e levantamento de pesos.
        <br/>
        O nome com o final “ite” remete à inflamação, porém não foi encontrado evidências disso em estudos.
        <br/>
        Ela é caracterizada por dor, no ponto de inserção dos tendões dos músculos do antebraço no cotovelo. 
      </>
    ),
  },
  {
    title: 'Posso fazer exercício com dor?',
    content: () => (
      <>
        Os exercícios físicos ajudam no <strong>controle</strong> da dor!
        <br/>
        A dor é interpretada pelo cérebro, e isto é realizado através de hormônios e neurotransmissores.
        <br/>
        A atividade física estimula hormônios e neurotransmissores relacionados ao bem estar.
        <br/>
        Dessa forma é capaz de melhorar qualidade de sono, dor, cansaço, e até mesmo depressão. 
      </>
    ),
  },
  {
    title: 'Você está na menopausa? Saiba qual exame você deve fazer!',
    content: () => (
      <>
        A menopausa é uma fase de muitas mudanças para as mulheres. 
        <br/>
        A maioria dessas alterações geram vários desconfortos, que podem diminuir muito a qualidade de vida. 
        <br/>
        Com esse turbilhão de mudanças tão sintomáticas, muitas vezes é esquecido uma alteração importantíssima, porém que só gera sintomas quando já apresenta sequelas graves. 
        <br/>
        Estou falando da osteoporose!
        <br/>
        O risco de osteoporose aumenta muito após a menopausa!
        <br/>
        E ela é silenciosa! 
        <br/>
        Só vai dar sinal quando ocorrer alguma fratura! O que pode acabar com o sonho de um envelhecimento saudável e com qualidade.   
        <br/>
        Por isso, todas as mulheres pós menopausa devem ser investigadas em relação à sua saúde óssea.
        <br/>
        O principal exame para isso é a densitometria óssea. 
        <br/>
        Procure um reumatologista! Envelheça com saúde!
      </>
    ),
  },
  {
    title: 'Fibrofog: você sabe o que é? ',
    content: () => (
      <>
        Você esquece com frequência palavras, nomes de pessoas? 
        <br/>
        Quando isso acontece você tem a sensação de que sua cabeça está “oca” ou “cheia de neblina”? 
        <br/>
        Isto pode ser um dos sintomas da fibromialgia! 
        <br/>
        Além de dor e cansaço, um sintoma muito comum é o chamado nevoeiro (fog = névoa), na qual sintomas cognitivos causam esquecimentos com frequência. 
        <br/>
        Uma explicação para isso é que para o cérebro, a dor é um sintoma importante, e ele gasta toda a energia lutando contra ela, dificultando outras atividades como a concentração. 
      </>
    ),
  },
  {
    title: 'Paciente com reumatismo consegue prever mudança no tempo?',
    content: () => (
      <>
        Chega esse tempinho de frio e chuva, as queixas de dores em pacientes com reumatismo com frequência aumentam. 
        <br/>
        Provavelmente, uma grande parcela das pessoas já ouviu essas queixas de seus parentes e amigos. 
        <br/>
        Mas será que realmente tem essa relação? 
        <br/>
        Estudos mais recentes têm demonstrado uma tendência à confirmação da influência das condições de tempo na intensidade da dor em pacientes reumatológicos. 
        <br/>
        Características como diminuição da temperatura, e aumento da pressão atmosférica e umidade tem sido mostrados como os vilões para que isto ocorra. 
        <br/>
        Porém, ainda não há consenso para que possamos bater o martelo nessa afirmação.
      </>
    ),
  },
  {
    title: 'Você é muito flexível?',
    content: () => (
      <>
        Algumas pessoas tem facilidade em fazer alguns movimentos que para outras são um sacrifício, como colocar as mãos no chão sem dobrar os joelhos! 
        <br/>
        Isso é causado pela síndrome da hibermobilidade articular benigna. 
        <br/>
        Ela provoca frouxidão dos ligamentos próximos às articulações e o que então, facilita a realização de alguns atividades, devido ao aumento da amplitude de movimento das articulações. 
        <br/>
        Como o próprio nome diz, ela é benigna! 
        <br/>
        Porém cuidados devem ser tomados, porque hoje sabemos que ela aumenta o risco de dores crônicas e fibromialgia.
      </>
    ),
  },
  {
    title: 'O que você precisa saber sobre fibromialgia?',
    content: () => (
      <>
        <ol>
          <li>
            A dor é real!
          </li>
          <li>
            Não é uma doença auto-imune;
          </li>
          <li>
            Não é uma doença inflamatória;
          </li>
          <li>
          O problema está no cérebro.
          </li>
        </ol>
        Tenho notado um aumento importante de novos casos, e piora dos sintomas em pessoas que já possuem o diagnóstico de fibromialgia, durante esse difícil período que estamos vivendo. 
        <br/>
        Um grande motivo de stress é entender o porquê e da onde vem tanta dor!! 
        <br/>
        Então hoje vou explicar para vocês! 
        <br/>
        A dor não se deve a lesões nos ossos, músculos ou articulações. 
        <br/>
        A dor é gerada no cérebro, quando recebe estímulos que não deveriam gerar experiências ruins, como um toque, ou até mesmo um abraço (já ouvi isso de mais um paciente). 
        <br/>
        O cérebro em uma pessoa com fibromialgia está (vamos dizer de uma maneira mais fácil) com o volume de dor desregulado! 
        <br/>
        Isso acontece por vários motivos e o mais importante de tudo, tem tratamento!!
      </>
    ),
  },
  {
    title: '6 dicas para um sono saudável!',
    content: () => (
      <>
        O sono, mais especificamente a falta dele é um grande problema, quem já passou por isso sabe! 
        <br/>
        A fibromialgia acarreta distúrbios do sono, que pioram tanto a dor quanto o cansaço. 
        <br/>
        Por isso, tragohoje algumas dicas para HIGIENE DO SONO! 
        <br/>
        Elas compreendem algumas mudanças de atitude, que podem ajudar e muito a qualidade do seu sono!!
        
        <ol>
          <li>
            Evite estimulantes próximo ao horário de dormir;
          </li>
          <li>
            Pratique atividade física regularmente;
          </li>
          <li>
            Estabeleça uma rotina: horário para se deitar e levantar;
          </li>
          <li>
            Evite cochilos durante o dia;
          </li>
          <li>
            Evite ambientes com iluminação excessiva e barulhos;
          </li>
          <li>
            Cama só para dormir: evite usar a cama para ler, assistir TV, estudar, ...
          </li>
        </ol>
      </>
    ),
  },
  {
    title: 'Como escolher o sapato adequado para atividade física: 7 dicas!',
    content: () => (
      <>
        Sempre falo sobre a importância e os benefícios da atividade física. 
        <br/>
        No entanto, ao escolher um esporte, é preciso ficar atento para não cometer erros que possam gerar problemas, como dores e lesões. 
        <br/>
        Por isso, trago algumas dicas para a escolha adequada do seu tênis para a prática esportiva. 

        <ol>
          <li>
            Comprar calçado para o esporte específico para o qual pretende usá-lo;
          </li>
          <li>
            No momento da compra, levar as meias que pretende usar;
          </li>
          <li>
            Deixar um espaço de um dedo de largura na frente dos dedos;
          </li>
          <li>
            Os tênis para corrida devem ser maiores do que os calçados do dia-a-dia;
          </li>
          <li>
            O calçado deve ficar nivelado quando colocado sobre uma superfície plana;
          </li>
          <li>
            Comprar de acordo com o seu tipo e formato de pés;
          </li>
          <li>
            Verificar frequentemente se há desgastes, pois estes aumentam a probabilidade de lesões.
          </li>
        </ol>
      </>
    ),
  },
  {
    title: 'Joanete: 5 informações que você precisa saber!',
    content: () => (
      <>
        O hálux valgo, popularmente conhecido como joanete, é uma das deformidades mais frequente dos pés.
        <br/>
        23% dos adultos e 35% dos idosos podem ser atingidos.
        <br/>
        Nesse caso, os vilões são vários: fatores genéticos, uso de sapatos inadequados (exemplo: os saltos altos), além de algumas doenças como a artrite reumatóide.

        <ol>
          <li>
            Termo médico correto é hálux valgo;
          </li>
          <li>
            Relação importante com a osteoartrite (artrose);
          </li>
          <li>
            Pode ser causado pelo uso de sapatos inadequados;
          </li>
          <li>
            Um dos sintomas mais comuns é a dor para calçar sapatos fechados;
          </li>
          <li>
            Cirurgia está indicada quando a dor e a perda de função não respondem ao tratamento clínico.
          </li>
        </ol>
      </>
    ),
  },
  {
    title: 'Vitamina D: vamos saber mais sobre este assunto?',
    content: () => (
      <>
        <ul>
          <li>
            O sol é a principal fonte;
          </li>
          <li>
            Deve expor tronco, braços e pernas ao sol por 10 a 15 minutos por dia;
          </li>
          <li>
            Alimentos também podem ser fonte de vitamina D;
          </li>
          <li>
            Os cogumelos são uma fonte alimentar não animal;
          </li>
          <li>
            Alimentos como salmão, atum, sardinha, gema de ovo, lentilha e queijos contêm vitamina D;
          </li>
          <li>
            Para a população saudável (até 60 anos), o nível de vitamina D deve ser maior que 20 ng/ml;
          </li>
          <li>
            São definidos como grupos de risco: idosos, gestantes, pacientes com osteoporose, doenças autoimunes e renais crônicos - estes devem alcançar níveis entre 30-60 ng/ml.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Pescoço de texto/ tecnológico: o que é isso?',
    content: () => (
      <>
        Tem-se notado o aumento da incidência de dores cervicais nos últimos anos. 
        <br/>
        Algumas pesquisas relacionam ao aumento do uso de smartphones. 
        <br/>
        A relação de flexão do pescoço durante o uso de dispositivos eletrônicos e alterações biomecânicas tem sido estudada, porém são necessários mais estudos para estabelecer a causalidade.
        <ul>
          <li>
            Caracterizado por dor, espasmos e contratura muscular em região cervical;
          </li>
          <li>
            Para ajudar:
          </li>
          <ul>
            <li>
              Use mais os áudios dos smartphones, dando preferência aos fones e deixando as mãos livres;
            </li>
            <li>
              Faça atividade física regularmente;
            </li>
            <li>
              Posicione o aparelho na altura dos olhos e aumente o tamanho das letras.
            </li>
          </ul>
        </ul>
      </>
    ),
  },
  {
    title: 'Dor: o que devo prestar atenção para contar ao médico?',
    content: () => (
      <>
        Durante a consulta, nós médicos fazemos algumas perguntas que nos norteiam para pensar em possíveis diagnósticos.
        <br/>
        Por isso, hoje trago algumas informações, que você que está com dor deve prestar atenção para contar ao seu médico.
        <ul>
          <li>
          Caráter da dor: como a dor se apresenta (queimação, pontada, cólica, pulsátil, “choque”, “aperto”);
          </li>
          <li>
          Localização da dor: atentar para o local onde ocorre a dor;
          </li>
          <li>
          Duração: quantos dias, semanas ou meses essa dor está presente?
          </li>
          <li>
          Irradiação: a dor “corre” para outro local ou fica sempre no mesmo?
          </li>
          <li>
          Intensidade: pedimos ao paciente que quantifique a dor de 0 a 10 (0- sem dor/ 10 – pior dor que já sentiu na vida);
          </li>
          <li>
          Evolução: a dor continua do “mesmo jeito” desde que começou? Ou mudou de local, intensidade, ...?
          </li>
          <li>
          Fator de piora: algo piora a dor? - alguma posição, horário do dia,...
          </li>
          <li>
          Fator de melhora: algo melhora a dor? - alguma posição, horário do dia, medicação,...
          </li>
        </ul>
        <strong>Dicas de postura para atividades diárias</strong>
        <ul>
          <li>
            Ao levantar um objeto do chão:
            <ul>
              <li>
                Não dobre as costas ou joelhos;
              </li>
              <li>
                Procure apoiar um dos joelhos no chão;
              </li>
              <li>
                Levante o objeto junto ao corpo, próximo da cintura.
              </li>
            </ul>
          </li>
          <li>
            Ao carregar peso: distribua o peso em duas sacolas;
          </li>
          <li>
            Use sapatos com bicos quadrado: salto 1 a 2 cm;
          </li>
          <li>
            Para amarrar os sapatos, traga o pé de encontro ao corpo, nunca a coluna em direção ao pé;
          </li>
          <li>
            Ao dormir:
            <ul>
              <li>
                O travesseiro deve ter a altura do ombro;
              </li>
              <li>
                Deite-se de lado, com um travesseiro entre as pernas para distribuir melhor o peso sobre a coluna.
              </li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
];

const Faq = () => (
  <Section id="perguntas frequentes">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Perguntas Frequentes</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
