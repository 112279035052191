import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import ExternalLink from '@common/ExternalLink';
import '@styles/IconStyles.css';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <div className="floatingIcon">
      <ExternalLink
        key="https://api.whatsapp.com/send?phone=5544998804915&text=Olá!%20Gostaria%20de%20marcar%20consulta%20com%20a%20Dra.%20Sueny."
        href="https://api.whatsapp.com/send?phone=5544998804915&text=Olá!%20Gostaria%20de%20marcar%20consulta%20com%20a%20Dra.%20Sueny.">
        <FontAwesomeIcon className="whatsAppIcon" icon={faWhatsapp} />
      </ExternalLink>
    </div>
    <Navbar />
    <Header />
    <About />
    <Faq />
    <Footer />
  </Layout>
);

export default IndexPage;
